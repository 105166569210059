/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import {
  AFIARI_BASE_URL,
  MERCHANT_BASE_URL,
  ECOMMERCE_BASE_URL,
  UTILITY_BASE_URL,
  userDetails,
} from "./constants";
import { IDENTITY_SERVER } from "./config";
import {
  getServiceRegion,
  getTimeZone,
  getUser,
  isUserLoggedIn,
} from "../tools/utils";

const identityAxiosInstance = axios.create({
  baseURL: AFIARI_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Apikey: process.env.REACT_APP_IDENTITY_API_KEY,
    Authorization: `Bearer ${getUser()?.token}`,
  },
});

export const merchantAxiosInstance = axios.create({
  baseURL: MERCHANT_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Apikey: process.env.REACT_APP_MERCHANT_API_KEY,
    Authorization: `Bearer ${getUser()?.token}`,
    ClientTimeZone: getTimeZone(),
    "SERVICE-REGION": getServiceRegion()?.id ?? "",
  },
});

export const ecommerceAxiosInstance = axios.create({
  baseURL: ECOMMERCE_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Apikey: process.env.REACT_APP_COMMERCE_API_KEY,
    Authorization: `Bearer ${getUser()?.token}`,
    ClientTimeZone: getTimeZone(),
    "SERVICE-REGION": getServiceRegion()?.id ?? "",
  },
});

export const utilityAxiosInstance = axios.create({
  baseURL: UTILITY_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Apikey: process.env.REACT_APP_UTILITY_API_KEY,
    Authorization: `Bearer ${getUser()?.token}`,
    ClientTimeZone: getTimeZone(),
    "SERVICE-REGION": getServiceRegion()?.id ?? "",
  },
});

const payload = {
  refreshToken: getUser().refresh_token,
  bearerToken: getUser().token,
  userId: getUser().user_id,
};

export const handleLogOut = async () => {
  try {
    const response = await identityAxiosInstance.post(
      `${IDENTITY_SERVER.AUTH_CONTROLLER.logOut.route}`,
      payload
    );
    if (response?.data.status) {
      sessionStorage.removeItem(userDetails);
      window.location.href = "/";
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/************************** REQUEST INTERCEPTORS STARTS HERE ************************ */

identityAxiosInstance.interceptors.request.use((config) => {
  config.headers["SERVICE-REGION"] = getServiceRegion()?.id ?? "";
  if (getUser()?.token)
    config.headers.Authorization = `Bearer ${getUser()?.token || ""}`;
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
});

merchantAxiosInstance.interceptors.request.use((config) => {
  config.headers["SERVICE-REGION"] = getServiceRegion()?.id ?? "";

  if (getUser()?.token)
    config.headers.Authorization = `Bearer ${getUser()?.token || ""}`;
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
});

ecommerceAxiosInstance.interceptors.request.use((config) => {
  config.headers["SERVICE-REGION"] = getServiceRegion()?.id ?? "";
  if (getUser()?.token)
    config.headers.Authorization = `Bearer ${getUser()?.token || ""}`;
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
});

utilityAxiosInstance.interceptors.request.use((config) => {
  config.headers["SERVICE-REGION"] = getServiceRegion()?.id ?? "";
  if (getUser()?.token)
    config.headers.Authorization = `Bearer ${getUser()?.token || ""}`;
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
});

/**************************** REQUEST INTERCEPTORS ENDS HERE ************************ */

/********************* RESPONSE INTERCEPTORS STARTS HERE ******************** */
identityAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 404) {
      console.log("NOT FOUND");
    }

    if (error.response.status === 401 && isUserLoggedIn()) {
      // do something
      const refreshToken = getUser()?.refresh_token;
      const bearerToken = getUser()?.token;
      const userId = getUser()?.user_id;

      try {
        const response = await identityAxiosInstance.post(
          IDENTITY_SERVER.AUTH_CONTROLLER.refreshToken.route,
          {
            refreshToken,
            bearerToken,
            userId,
          }
        );

        axios.defaults.headers.common.Authorization = `Bearer ${
          getUser()?.token
        }`;

        if (response?.data?.status) {
          localStorage.setItem(
            userDetails,
            JSON.stringify(response?.data?.result[0])
          );
          error.config.headers.Authorization = `Bearer ${response?.data?.result[0]?.token}`;
        }
        if (!response?.data?.status) {
          handleLogOut();
        }
        return await merchantAxiosInstance(error.config);
      } catch (_error) {
        return await Promise.reject(_error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

merchantAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 404) {
      console.log("NOT FOUND");
    }

    if (error?.response?.status === 401 && isUserLoggedIn()) {
      // do something
      const refreshToken = getUser()?.refresh_token;
      const bearerToken = getUser()?.token;
      const userId = getUser()?.user_id;

      try {
        const response = await identityAxiosInstance.post(
          IDENTITY_SERVER.AUTH_CONTROLLER.refreshToken.route,
          {
            refreshToken,
            bearerToken,
            userId,
          }
        );

        axios.defaults.headers.common.Authorization = `Bearer ${
          getUser()?.token
        }`;

        if (response?.data?.status) {
          localStorage.setItem(
            userDetails,
            JSON.stringify(response?.data?.result[0])
          );
          error.config.headers.Authorization = `Bearer ${response?.data?.result[0]?.token}`;
        }
        if (!response?.data?.status) {
          handleLogOut();
        }
        return await merchantAxiosInstance(error.config);
      } catch (_error) {
        return await Promise.reject(_error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

ecommerceAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 404) {
      // do something
      console.log("NOT FOUND");
    }
    if (error.response.status === 401 && isUserLoggedIn()) {
      // do something
      const refreshToken = getUser()?.refresh_token;
      const bearerToken = getUser()?.token;
      const userId = getUser()?.user_id;
      try {
        const response = await identityAxiosInstance.post(
          IDENTITY_SERVER.AUTH_CONTROLLER.refreshToken.route,
          {
            refreshToken,
            bearerToken,
            userId,
          }
        );

        axios.defaults.headers.common.Authorization = `Bearer ${
          getUser()?.token
        }`;

        if (response?.data?.status) {
          localStorage.setItem(
            userDetails,
            JSON.stringify(response?.data?.result[0])
          );
          error.config.headers.Authorization = `Bearer ${response?.data?.result[0]?.token}`;
        }
        if (!response?.data?.status) {
          handleLogOut();
        }
        return await ecommerceAxiosInstance(error.config);
      } catch (_error) {
        return await Promise.reject(_error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

utilityAxiosInstance.interceptors.request.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error.response);
    if (error.response.status === 404) {
      // do something
      console.log("NOT FOUND");
    }
    if (error.response.status === 401 && isUserLoggedIn()) {
      // do something
      const refreshToken = getUser()?.refresh_token;
      const bearerToken = getUser()?.token;
      const userId = getUser()?.user_id;
      try {
        const response = await identityAxiosInstance.post(
          IDENTITY_SERVER.AUTH_CONTROLLER.refreshToken.route,
          {
            refreshToken,
            bearerToken,
            userId,
          }
        );

        axios.defaults.headers.common.Authorization = `Bearer ${
          getUser()?.token
        }`;

        if (response?.data?.status) {
          localStorage.setItem(
            userDetails,
            JSON.stringify(response?.data?.result[0])
          );
          error.config.headers.Authorization = `Bearer ${response?.data?.result[0]?.token}`;
        }
        if (!response?.data?.status) {
          handleLogOut();
        }
        return await utilityAxiosInstance(error.config);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (_error) {
        return await Promise.reject(_error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

/********************** RESPONSE INTERCEPTORS ENDS HERE *************************** */

export default identityAxiosInstance;
